import { useEffect, useState } from "react";
import { Template } from "../../../../models/template";
import { BaseInput, Scroll, Toggle } from "../../../../shared/components";
import {
  Button,
  FlexColumn,
  FlexContainer,
  FlexRow,
  Text,
} from "../../../../shared/styled";
import { Table } from "../components/Table";
import {
  HttpPaginatedTemplatesListResponseFilters,
  httpAdminTemplateList,
  httpAdminUpdateTemplate,
} from "../../../../apis/admin";
import { colors, theme } from "../../../../constants";
import { useNavigate } from "react-router-dom";

export const Templates = () => {
  const navigate = useNavigate();

  const [data, setData] = useState<Template[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] =
    useState<HttpPaginatedTemplatesListResponseFilters>({});
  const [order, setOrder] = useState<string[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>();

  const fetch = async () => {
    setLoading(true);
    try {
      const response = await httpAdminTemplateList(
        search,
        offset,
        limit,
        order,
        filters
      );
      setData(response?.data?.results);
      setTotal(response?.data?.count);
    } catch (err) { }
    setLoading(false);
  };

  const toggleStatus = async (id: number | string, value: boolean) => {
    setLoading(true);
    try {
      const response = await httpAdminUpdateTemplate(id, { is_active: value });
      fetch();
      // TODO Add Notifications
    } catch (err) {
      err;
      // TODO Add Notifications
    }
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, [order, limit, offset, filters, search]);

  return (
    <Scroll wrapperStyle={{ dimensions: { flex: 1 } }}>
      <FlexContainer
        dimensions={{ width: "100%" }}
        padding={{ left: "32px", right: "32px", top: "32px" }}
      >
        <Table<Template>
          header={(value) => true}
          loading={isLoading}
          tableShadow={`0px 4px 4px 0px ${theme.shadow.color}`}
          pagination={{
            limit: limit,
            setLimit: setLimit,
            offset: offset,
            setOffset: setOffset,
            total: total,
          }}
          orders={{
            orderableFields: [
              "id",
              "name",
              "is_active",
              "created_at",
              "updated_at",
            ],
            setOrder: setOrder,
            value: order,
          }}
          columns={[
            {
              label: "No",
              key: "id",
              styling: { maxWidth: "fit-content" },
              mapper: (item) => item?.id || <>-</>,
            },
            {
              label: "Name",
              key: "name",
            },
            {
              label: "Type",
              key: "type",
              mapper: (item) => item?.type_name || <>-</>,
            },
            {
              label: "Status",
              key: "is_active",
              styling: { maxWidth: "100%" },
              mapper: (item) => (
                <Toggle
                  checked={!item?.is_active}
                  onChange={async () => {
                    if (item?.id !== undefined) return;

                    toggleStatus(item.id, !item.is_active);
                  }}
                  backgroundColorChecked="#2CD06E"
                  backgroundColorUnchecked="#E0E0E0"
                  width={28}
                  height={16}
                  sliderHeight={12}
                  sliderWidth={12}
                  translate={9}
                />
              ),
            },
            {
              label: "Actions",
              styling: {
                maxWidth: "100%",
              },
              mapper: (item) => (
                <Button onClick={() => navigate(`/admin/templates/${item.id}`)}>
                  Edit
                </Button>
              ),
            },
          ]}
          data={data}
          headerDimensions={{ width: "100%", height: "120px" }}
          headerComponent={
            <FlexColumn
              alignItems="flex-end"
              gap="5px"
              padding={{ top: "16px", bottom: "16px" }}
              dimensions={{ width: "20%" }}
            >
              <BaseInput
                placeholder="Search..."
                value={search}
                onChange={async (e) => setSearch(e.target.value)}
                dimensions={{ width: "100%", height: "32px" }}
              />
              <Button
                background={colors.white.clean}
                border={{
                  color: theme.colors.disabled,
                  width: "1px",
                  radius: "8px",
                }}
                justifyContent="center"
                alignItems="center"
                padding={{ left: "16px", right: "16px" }}
                onClick={() => navigate("/admin/templates/new")}
                dimensions={{ width: "fit-content", height: "32px" }}
              >
                <FlexRow gap="10px">
                  <Text color={theme.colors.secondary} size="14px" weight={500}>
                    Create Template
                  </Text>
                </FlexRow>
              </Button>
            </FlexColumn>
          }
        />
      </FlexContainer>
    </Scroll>
  );
};
