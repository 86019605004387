import { BrowserRouter, Routes } from "react-router-dom";
import { AuthProvider, ModalProvider } from "./contexts";

import AppRoutes from "./screens";
import { GlobalEventProvider } from "./contexts/GlobalEventProvider";

const App = () => {
  return (
    <GlobalEventProvider>
      <ModalProvider>
        <BrowserRouter>
          <AuthProvider>
            <Routes>{AppRoutes}</Routes>
          </AuthProvider>
        </BrowserRouter>
      </ModalProvider>
    </GlobalEventProvider>
  );
};


export default App;
