import React, { useEffect } from "react";
import {
  useNavigate,
  useLocation,
  NavigateFunction,
  Location,
} from "react-router-dom";

import { User } from "../models/user";
import { REACT_APP_DEBUG_ENV } from "../envs";
import {
  httpAuthGetCurrentUser,
  httpAuthLogin,
  httpAuthLogout,
} from "../apis/auth";

interface AuthContextInterface {
  currentUser?: User;
  login(email: string, password: string): Promise<void>;
  logout(): void;
}

const AuthContext = React.createContext<AuthContextInterface>({
  currentUser: undefined,
  login: async (email: string, password: string) => {},
  logout: () => {},
});

interface AuthProviderInterface {
  children?: React.ReactNode;
}

const redirectHandler = (
  user: User | undefined | null,
  navigate: NavigateFunction,
  location: Location
) => {
  if (REACT_APP_DEBUG_ENV) return;

  // Navigate to login if not logged in
  if (!user) {
    if(location.pathname === "/map") return;
    if (!location.pathname.includes("/login")) navigate("/login");
    return;
  }

  // Navigate to agent dashboard if not admin but route is admin
  //if (location?.pathname.includes("/admin")) {
  //  navigate("/agent/leads");
  //  return;
  //}
  //
  //if (!location?.pathname.includes("/agent")) navigate("/agent/leads");
};

export function AuthProvider({ children }: AuthProviderInterface) {
  const navigate = useNavigate();
  const location = useLocation();

  const [currentUser, setCurrentUser] = React.useState<User>();
  const [isReady, setReady] = React.useState<boolean>(false);
  //TODO implement user login

  const getUser = async () => {
    try {
      const response = await httpAuthGetCurrentUser();
      localStorage.setItem("token", response?.data?.token as string);
      setCurrentUser(response?.data);
    } catch (err) {
      //TODO Add notification and error handling
      //if (!REACT_APP_DEBUG_ENV) navigate("/login");
      console.log("AuthProvider.getUser", err);
    }

    setReady(true);
  };

  useEffect(() => {
    getUser();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const response = await httpAuthLogin({ email, password });
      localStorage.setItem("token", response?.data?.token as string);
      setCurrentUser(response?.data);
    } catch (err) {
      //TODO Add notification and error handling
      console.log("AuthProvider.login", err);
    }
  };

  const logout = () => {
    httpAuthLogout()
      .then((res) => {
        localStorage.removeItem("token");
        navigate("/login");
      })
      .catch((err) => {
        //TODO Add notification and error handling
      });
  };

  useEffect(() => {
    redirectHandler(currentUser, navigate, location);
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, login, logout }}>
      {REACT_APP_DEBUG_ENV && children}
      {!REACT_APP_DEBUG_ENV && (
        <>
          {isReady && children}
          {!isReady && "Loading"}
        </>
      )}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}
