import { httpPut } from "../../utilities";

export interface HttpAdminUpdateTemplate {
  name?: string;
  type?: number;
  content?: string;
  is_active?: boolean;
}

export const httpAdminUpdateTemplate = async (
  id: number | string,
  data: HttpAdminUpdateTemplate
) => {
  return httpPut(`/admin/template/${id}`, data);
};
