import { useEffect, useState } from "react";
import {
  HttpAdminUpdateTemplate,
  httpAdminRetriveTemplateList,
  httpAdminUpdateTemplate,
} from "../../../../apis/admin";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { httpAdminCreateTemplateList } from "../../../../apis/admin/template/adminCreateTemplate";
import { Toaster } from "react-hot-toast";
import {
  Button,
  FlexColumn,
  FlexContainer,
  FlexRow,
  Text,
  Title,
  TitleXXL,
} from "../../../../shared/styled";
import { Loading } from "../../../../shared/components";
import { theme } from "../../../../constants";
import { StyledBaseInput } from "../../../../shared/components/Input/StyledBaseInput";

interface FormData extends HttpAdminUpdateTemplate { }

export const ManageTemplate = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const [isLoading, setLoading] = useState<boolean>(true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormData>();

  const fetch = async (id: string | number) => {
    setLoading(true);
    try {
      const response = await httpAdminRetriveTemplateList(id);
      setValue("content", response?.data?.content);
      setValue("type", response?.data?.type);
      setValue("name", response?.data?.name);
    } catch (err) {
      err;
      // TODO Add notification
    }
    setLoading(false);
  };

  const create: SubmitHandler<FormData> = async (data) => {
    setLoading(true);
    try {
      await httpAdminCreateTemplateList(data);
      navigate("/admin/templates");
      // TODO Add Notifications
    } catch (err) {
      err;
      // TODO Add Notifications
    }
    setLoading(false);
  };

  const update: SubmitHandler<FormData> = async (data) => {
    if (!id) return;
    setLoading(true);
    try {
      await httpAdminUpdateTemplate(id, data);
      navigate("/admin/templates");
      // TODO Add Notifications
    } catch (err) {
      err;
      // TODO Add Notifications
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id && `${id}` !== "-1") fetch(id);
  }, [id]);

  const isCreate = (id && `${id}` !== "-1") || !id;

  return (
    <>
      <Toaster position="bottom-left" reverseOrder={false} />
      <FlexContainer
        flexDirection="column"
        dimensions={{ width: "100%", height: "100%" }}
        padding={{ left: "32px", right: "32px", top: "64px" }}
      >
        {isLoading && <Loading />}
        {!isLoading && (
          <form onSubmit={handleSubmit(isCreate ? create : update)}>
            <FlexColumn
              dimensions={{ width: "100%" }}
              gap="10px"
              background={{ color: "white" }}
              style={{
                boxShadow: ` 0px 4px 4px${theme.shadow.color}`,
                borderRadius: "16px 16px 16px 16px",
              }}
            >
              <FlexRow
                dimensions={{ width: "100%" }}
                padding={{ bottom: "16px" }}
              >
                <FlexRow
                  style={{ borderRadius: "16px 16px 0px 0px" }}
                  justifyContent="flex-start"
                  alignItems="center"
                  gap="20px"
                  dimensions={{ height: "72px", width: "100%" }}
                  background={{ color: "#E4ECFB" }}
                  padding={{
                    top: "4px",
                    bottom: "4px",
                    left: "40px",
                    right: "40px",
                  }}
                >
                  <TitleXXL text-align="center" color={theme.colors.primary}>
                    {isCreate ? <>Create Template</> : <>Edit Template</>}
                  </TitleXXL>
                </FlexRow>
              </FlexRow>
              <FlexColumn gap="48px">
                <FlexRow
                  dimensions={{ width: "100%", height: "100%" }}
                  padding={{ top: "16px", left: "40px", right: "40px" }}
                  gap="100px"
                >
                  <FlexColumn
                    gap="40px"
                    dimensions={{ width: "100%", height: "100%" }}
                  >
                    <FlexColumn
                      gap="8px"
                      dimensions={{ width: "100%", height: "32px" }}
                    >
                      <Title text-align="left" color={theme.font.color}>
                        Name
                      </Title>
                      <StyledBaseInput
                        type="text"
                        dimensions={{ width: "100%", height: "24px" }}
                        placeholder="Enter template name..."
                        {...register("name", { required: true })}
                      />
                    </FlexColumn>
                  </FlexColumn>
                  <FlexColumn
                    gap="40px"
                    dimensions={{ width: "100%", height: "100%" }}
                  ></FlexColumn>
                </FlexRow>
                <FlexRow
                  justifyContent="flex-end"
                  gap="32px"
                  padding={{ left: "40px", right: "40px", bottom: "40px" }}
                >
                  <Button
                    justifyContent="center"
                    alignItems="center"
                    dimensions={{ width: "auto", height: "40px" }}
                    background={{ color: theme.colors.danger }}
                    border={{ radius: "8px" }}
                    onClick={() => navigate("/admin/templates")}
                  >
                    <Text color="white">Cancel</Text>
                  </Button>
                  <Button
                    justifyContent="center"
                    alignItems="center"
                    dimensions={{ width: "auto", height: "40px" }}
                    background={{ color: theme.colors.primary }}
                    border={{ radius: "8px" }}
                    type="submit"
                  >
                    <Text color="white">Submit</Text>
                  </Button>
                </FlexRow>
              </FlexColumn>
            </FlexColumn>
          </form>
        )}
      </FlexContainer>
    </>
  );
};
