import { styled } from "styled-components";
import { Note } from "../../../../../models/note";
import { colors, theme } from "../../../../../constants";
import {
  LoadingImage,
  Scroll,
} from "../../../../../shared/components";
import { Button, FlexColumn, Text } from "../../../../../shared/styled";
import { useGlobalEvents } from "../../../../../contexts/GlobalEventProvider";
import { useEffect, useState } from "react";
import { toReadableDate } from "../../../../../utilities/datetime";
import { httpCreateNote } from "../../../../../apis/leads";

const LeadNotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: absolute;
  top: 40px;

  max-width: 340px;
  width: 100%;
  gap: 5px;

  background: ${colors.white.clean};
  border: solid 1px ${colors.white.gallery};
  border-radius: 5px;

  box-sizing: border-box;
  padding: 10px;

  box-shadow: 0px 0px 4px ${theme.shadow.color};
`;

const LeadNotesMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  box-sizing: border-box;
  padding: 4px;

  background: ${colors.blue.onahau};

  text-transform: none;
`;

interface LeadNotesTextAreaProps { }

const LeadNotesTextArea = styled.textarea<LeadNotesTextAreaProps>`
  height: 72px;

  padding: 10px;

  color: ${theme.font.color};
  font-size: ${theme.font.size.x}px;

  outline: none;

  border: solid 1px ${colors.grey.gallery};
  border-radius: 6px;

  resize: none;

  &::-webkit-scrollbar {
    margin-right: 2px;
    width: 4px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.primary}; /* Color of the thumb */
    border-radius: 5px; /* Rounded corners */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: ${colors.white.gallery};
  }

  &:focus {
    outline: none;
    border-color: ${theme.colors.primary};
  }
`;

interface LeadNotesProps {
  notes: Note[];
  close?: () => void;
  leadId?: number;
}

interface State {
  mouseIn?: boolean;
}

export const LeadNotes = ({ leadId, notes, close }: LeadNotesProps) => {
  const { mouseClick, keyPressed } = useGlobalEvents();

  const [state, setState] = useState<State>({});

  const [message, setMessage] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const submit = async () => {
    if (!leadId && !message) return;

    setLoading(true);
    try {
      await httpCreateNote(leadId as number, { content: message });
      setMessage("");
    } catch (err) {
      console.log("LeadNotes", err);
    }
    setLoading(false);
  };

  useEffect(() => {
    //!state?.mouseIn && close && close();
    console.log("CLICK")
  }, [keyPressed]);

  return (
    <LeadNotesWrapper
      onMouseEnter={() => setState({ ...state, mouseIn: true })}
      onMouseLeave={() => setState({ ...state, mouseIn: false })}
    >
      {notes.map((note) => (
        <FlexColumn gap="5px" margin={{ bottom: "10px" }}>
          <LeadNotesMessageWrapper>
            <Text
              color={theme?.colors?.primary}
              weight="bold"
              size={`${theme?.font?.size?.x}px`}
            >
              {note?.agent?.first_name} {note?.agent?.last_name}
            </Text>
            <Text size={`${theme?.font?.size?.x}px`} whiteSpace="normal">{note?.content}</Text>
          </LeadNotesMessageWrapper>
          <Text
            weight="bold"
            size={`${theme?.font?.size?.sx}px`}
            align="right"
          >
            {toReadableDate(note?.created_at as Date)}
          </Text>
        </FlexColumn>
      ))}
      <LeadNotesTextArea
        value={message}
        placeholder="Write something..."
        onChange={(event) => setMessage(event?.currentTarget?.value)}
        onKeyDown={(event) => {
          event?.key === "Enter" && !keyPressed?.["Shift"] && submit();
          event?.key === "Escape" && close && close()
        }}
      />
      <Button disabled={isLoading} onClick={() => submit()}>
        {!isLoading && <>Submit</>}
        {isLoading && (
          <LoadingImage
            src="/assets/images/loading.svg"
            dimensions={{ width: "16px", height: "16px" }}
          />
        )}
      </Button>
    </LeadNotesWrapper>
  );
};
