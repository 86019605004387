import { Outlet } from "react-router-dom";
import { FlexColumn, FlexRow } from "../../shared/styled";
import { IconTypes } from "../../shared/components";
import { Content, Header, Navbar } from "../components";

const NavbarItems = [
  { path: "/admin/overview", icon: IconTypes.dashboardFilled, name: "Overview", id: "overview" },
  { path: "/admin/users", icon: IconTypes.users, name: "Users", id: "users" },
  { path: "/admin/leads", icon: IconTypes.people, name: "Leads", id: "leads" },
  { path: "/admin/statistics", icon: IconTypes.statistics, name: "Statistics", id: "statistics" },
  { path: "/admin/templates", icon: IconTypes.emailSmsTemplate, name: "Templates", id: "templates" },
];

export const AdminDashboard = () => {
  return (
    <FlexRow dimensions={{ width: "100vw", height: "100vh" }} overflow="hidden">
      <Navbar routes={NavbarItems} />
      <FlexColumn dimensions={{ flex: 1 }} padding="10px" gap="10px">
        <Header />
        <Content>
          <Outlet />
        </Content>
      </FlexColumn>
    </FlexRow>
  );
};
