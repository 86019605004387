import { httpPost } from "../../utilities";

export interface HttpAdminCreateTemplate {
  name?: string;
  type?: number;
  content?: string;
  is_active?: boolean;
}

export const httpAdminCreateTemplateList = async (data: HttpAdminCreateTemplate) => {
  return httpPost(`/admin/template`, data);
};
