import { RuleSet, styled } from "styled-components";

import { colors, theme } from "../../../constants";
import { BorderStyling, BorderStylingProps, DimensionStyling, DimensionStylingProps } from "../../styling";

interface StyledBaseInputProps {
  valid?: boolean;
  dimensions?: DimensionStylingProps;
  border?: BorderStylingProps;

  css?: RuleSet<object>;
}

export const StyledBaseInput = styled.input<StyledBaseInputProps>`
  padding: 4px 6px;

  color: ${theme.font.color};
  font-size: ${theme.font.size.x}px;

  background: ${colors.white.clean};

  border: solid 1.5px ${({ valid }) => valid || valid === undefined ? theme.border.color : theme.border.invalid};
  border-radius: 6px;

  outline: none;

  &:focus {
    border-color: ${theme.colors.primary};
  }

  &:invalid {
    border-color: ${theme.colors.danger};
  }

  ${({ dimensions }) => DimensionStyling({ ...dimensions })};
  ${({ border }) => BorderStyling({ ...border })};

  ${({ css }) => css};

  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type=number] {
    -moz-appearance: textfield;
  }
`

export const StyledCheckbox = styled(StyledBaseInput).attrs({ type: "checkbox" })`
  appearance: none;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: ${colors.white.clean};
  border: 1.5px solid
    ${({ valid }) => (valid || valid === undefined ? theme.border.color : theme.border.invalid)};
  border-radius: 50%;

  &:checked {
    background-color: ${theme.colors.primary}; 
    border-color: ${theme.colors.clean}; 
  }

  &:focus {
    border-color: ${theme.colors.primary};
  }
`;

export const StyledNumberInput = styled(StyledBaseInput).attrs({ type: "number" })`
  text-align: center;
  border-radius: 0;
  border: 1px solid ${theme.colors.primary};
  max-width: 85px;
    ${({ valid }) => (valid || valid === undefined ? theme.border.color : theme.border.invalid)};
  background-color: ${colors.white.clean};
  color: ${theme.font.color};

  padding: unset;

  height: 32px;

  &:focus {
    outline: none;
  }

  &:invalid {
    border-color: ${theme.colors.danger};
  }
 
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;